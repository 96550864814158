import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PRICING_TYPE } from "constant/status.constant";

export interface PlaceCategory {
  id: string;
  name: string;
  createdDate?: Date;
  menuItems: MenuItem[];
  placeId?: string;
  status: number;
  expanded?: boolean;
  description?: string;
  sections: Section[];
}

export interface OptionGroup {
  id?: string;
  name: string;
  createdDate?: Date;
  options: Option[];
  placeId?: string;
  optionRule: OptionRule;
}

export interface OptionRule {
  min: number;
  max: number;
  isRequired: boolean;
  selectionMode: number;
}

export interface Section {
  id: string;
  name: string;
  createdDate?: Date;
  placeId?: string;
  status: number;
}

export interface Option {
  id?: string;
  name: string;
  createdDate?: Date;
  price: number;
  placeId?: string;
  status: number;
  position: number;
}

export interface MenuItem {
  id: string;
  name: string;
  price: number;
  priceAfterDiscount: number;
  pricingType: number;
  pricingText?: string;
  status: string | number;
  createdDate?: Date;
  description?: string;
  image?: string;
  categoryId?: string;
  currency?: string;
}

export interface Field {
  field: string;
  value: string | number | boolean;
}

export interface PlaceState {
  name: string;
  categories: PlaceCategory[];
  optionGroups: OptionGroup[];
  sections: Section[];
  id?: string;
  url: string;
  createdDate?: Date | null;
  qrCode: string;
  shortUrl: string;
  menuItemCount: number | string | null;
  categoryCount: number | string | null;
  currency?: string;
  languages?: any;
  phoneNumber: string;
  isLockedForImporting: boolean;
  selectedSectionId: string;
}

const initialState: PlaceState = {
  name: "",
  categories: [],
  optionGroups: [],
  sections: [],
  url: "",
  createdDate: null,
  qrCode: "",
  shortUrl: "",
  phoneNumber: "",
  currency: "USD",
  menuItemCount: null,
  categoryCount: null,
  isLockedForImporting: false,
  selectedSectionId: "",
};

export const PlaceSlice = createSlice({
  name: "place",
  initialState,
  reducers: {
    setPlaces: (
      state,
      action: PayloadAction<{
        place: PlaceState;
      }>
    ) => {
      state.currency = action.payload.place.currency;
      state.isLockedForImporting = action.payload.place.isLockedForImporting;
    },
    setOptionGroups: (
      state,
      action: PayloadAction<{
        newOptionGroups: OptionGroup[];
      }>
    ) => {
      const prevOptionGroups = JSON.parse(JSON.stringify(state?.optionGroups));
      const newOptionGroups = action.payload.newOptionGroups?.map(
        (item, index) => {
          return {
            ...item,
            expanded: prevOptionGroups[index]?.expanded || false,
          };
        }
      );
      state.optionGroups = newOptionGroups;
    },
    setCategories: (
      state,
      action: PayloadAction<{
        categories: PlaceCategory[];
      }>
    ) => {
      const prevCategories = state.categories;
      state.categories = action.payload.categories?.map((item, index) => {
        return {
          ...item,
          expanded:
            prevCategories.find((x: any) => x.id === item.id)?.expanded ||
            false,
        };
      });
    },
    addItemsToCategory: (
      state,
      action: PayloadAction<{ index: number; count: number }>
    ) => {
      const newItem: MenuItem = {
        id: "",
        name: "New item",
        price: 0,
        priceAfterDiscount: 0,
        status: 2,
        pricingType: PRICING_TYPE["Fixed Price"],
      };
      state.categories = state.categories.map((item, index) => {
        if (action.payload.index === index) {
          const menuItems = item?.menuItems || [];
          let localCount = action.payload.count;
          while (localCount) {
            menuItems.push(newItem);
            --localCount;
          }
          return {
            ...item,
            expanded: true,
            menuItems,
          };
        }
        return item;
      });
    },
    changeCategoryPositions: (
      state,
      action: PayloadAction<{ categories: PlaceCategory[] }>
    ) => {
      const categories = state.categories.filter(
        (c) => action.payload.categories.findIndex((i) => i.id === c.id) < 0
      );
      state.categories = [...action.payload.categories, ...categories];
    },
    changeMenuItemPositions: (
      state,
      action: PayloadAction<{ categoryId: string; menuItems: MenuItem[] }>
    ) => {
      const category = state.categories.find(
        (c) => c.id === action.payload.categoryId
      );
      if (category) {
        const menuItems = category.menuItems.filter(
          (m) => action.payload.menuItems.findIndex((i) => i.id === m.id) < 0
        );
        category.menuItems = [...action.payload.menuItems, ...menuItems];
      }
    },
    updateCategoryProperties: (
      state,
      action: PayloadAction<{
        id: string;
        values: Field[];
      }>
    ) => {
      state.categories = state.categories.map((item: any) => {
        if (item.id === action.payload.id) {
          action.payload.values.forEach((prop) => {
            item[prop.field] = prop.value;
          });
        }
        return item;
      });
    },
    updateMenuItemProperties: (
      state,
      action: PayloadAction<{
        categoryId: string;
        id: string;
        values: Field[];
      }>
    ) => {
      state.categories = state.categories.map((item) => {
        if (action.payload.categoryId === item.id) {
          let menuItems: MenuItem[] = item.menuItems;
          menuItems = menuItems?.map((menu: any) => {
            if (menu.id == action.payload.id) {
              action.payload.values.forEach((prop) => {
                menu[prop.field] = prop.value;
              });
            }
            return menu;
          });
          return {
            ...item,
            menuItems,
          };
        }
        return item;
      });
    },
    searchItems: (
      state,
      action: PayloadAction<{
        newCategories: PlaceCategory[];
      }>
    ) => {
      state.categories = action.payload.newCategories;
    },
    deleteMenuItem: (state, action: PayloadAction<{ id: string }>) => {
      state.categories = state.categories.map((item, index) => {
        return {
          ...item,
          menuItems: item?.menuItems?.filter(
            (menu) => menu?.id != action.payload.id
          ),
        };
      });
    },
    deleteCategory: (state, action: PayloadAction<{ id: string }>) => {
      state.categories = state.categories.filter(
        (item) => item?.id != action?.payload?.id
      );
    },
    deleteOptionGroup: (state, action: PayloadAction<{ id: string }>) => {
      state.optionGroups = state.optionGroups.filter(
        (item) => item?.id != action?.payload?.id
      );
    },
    updateOptionGroupProperties: (
      state,
      action: PayloadAction<{
        item: OptionGroup;
        values: Field[];
      }>
    ) => {
      let optionGroups = state.optionGroups;
      optionGroups = optionGroups.map((item: any) => {
        if (item.id === action.payload.item.id) {
          action.payload.values.forEach((prop) => {
            item[prop.field] = prop.value;
          });
        }
        return item;
      });
      state.optionGroups = optionGroups;
    },
    updateOptionGroupRule: (
      state,
      action: PayloadAction<{
        optionGroupId: string;
        optionRule: OptionRule;
      }>
    ) => {
      let optionGroups = state.optionGroups;
      optionGroups = optionGroups.map((item: any, index) => {
        if (item.id === action.payload.optionGroupId) {
          item.optionRule = action.payload.optionRule;
        }
        return item;
      });
      state.optionGroups = optionGroups;
    },
    changePositionOptionGroup: (
      state,
      action: PayloadAction<{ optionGroups: OptionGroup[] }>
    ) => {
      state.optionGroups = action.payload.optionGroups;
    },
    updateOptionGroupMenuItems: (
      state,
      action: PayloadAction<{
        item: OptionGroup;
        menuItemIds: string[];
      }>
    ) => {
      let optionGroups = state.optionGroups;
      optionGroups = optionGroups.map((item: any, index) => {
        if (item.id === action.payload.item.id) {
          return {
            ...item,
            menuItemIds: action.payload.menuItemIds,
          };
        }
        return item;
      });
      state.optionGroups = optionGroups;
    },
    addOption: (
      state,
      action: PayloadAction<{ optionGroupId: string; option: Option }>
    ) => {
      let optionGroups = state.optionGroups;
      const optionGroup = optionGroups.find(
        (x) => x?.id == action?.payload?.optionGroupId
      );
      if (optionGroup != null) {
        optionGroup.options = [action?.payload?.option, ...optionGroup.options];
      }
      state.optionGroups = optionGroups;
    },
    updateOption: (
      state,
      action: PayloadAction<{
        optionGroupId: string;
        optionId: string;
        values: Field[];
      }>
    ) => {
      let optionGroups = state.optionGroups;
      const optionGroup = optionGroups.find(
        (x) => x?.id == action?.payload?.optionGroupId
      );
      if (optionGroup != null) {
        let options = optionGroup.options;
        options = options.map((item: any) => {
          if (item.id === action.payload.optionId) {
            action.payload.values.forEach((prop) => {
              item[prop.field] = prop.value;
            });
            return item;
          }
          return item;
        });
        optionGroup.options = [...options];
      }

      state.optionGroups = optionGroups;
    },
    deleteOption: (
      state,
      action: PayloadAction<{ optionGroupId: string; optionId: string }>
    ) => {
      let optionGroups = state.optionGroups;
      const optionGroup = optionGroups.find(
        (x) => x?.id == action?.payload?.optionGroupId
      );
      if (optionGroup != null) {
        optionGroup.options = optionGroup.options.filter(
          (item, index) => item?.id != action?.payload?.optionId
        );
      }
      state.optionGroups = optionGroups;
    },
    changePositionOption: (
      state,
      action: PayloadAction<{ optionGroupId: string; options: any }>
    ) => {
      let optionGroups = state.optionGroups;
      const optionGroup = optionGroups.find(
        (x) => x?.id == action?.payload?.optionGroupId
      );
      if (optionGroup != null) {
        optionGroup.options = action?.payload?.options;
      }
      state.optionGroups = optionGroups;
    },
    setSections: (
      state,
      action: PayloadAction<{
        newSections: Section[];
      }>
    ) => {
      const all = {
        id: "",
        name: "All",
      } as Section;
      state.sections = [all, ...action.payload.newSections];
    },
    addSection: (state, action: PayloadAction<{ newSection: Section }>) => {
      state.sections = [...state.sections, action.payload.newSection];
    },
    removeSection: (state, action: PayloadAction<{ id: string }>) => {
      state.sections = state.sections.filter((x) => x.id !== action.payload.id);
      state.categories.forEach((cat) => {
        cat.sections = cat.sections.filter((s) => s.id !== action.payload.id);
      });
    },
    updateSection: (
      state,
      action: PayloadAction<{ id: string; name: string }>
    ) => {
      const section = state.sections.find((x) => x.id === action.payload.id);
      if (section) {
        section.name = action.payload.name;
        state.categories.forEach((cat) => {
          cat.sections = cat.sections.map((s) => {
            if (s.id == action.payload.id) {
              return {
                ...s,
                name: section.name,
              };
            }
            return s;
          });
        });
      }
    },
    updateCategorySections: (
      state,
      action: PayloadAction<{ id: string; sectionIds: string[] }>
    ) => {
      const category = state.categories.find((x) => x.id === action.payload.id);
      if (category) {
        category.sections = state.sections.filter((x) =>
          action.payload.sectionIds.includes(x.id)
        );
      }
    },
    updateSectionCategories: (
      state,
      action: PayloadAction<{ id: string; categoryIds: string[] }>
    ) => {
      const section = state.sections.find((x) => x.id === action.payload.id);
      if (section) {
        state.categories.forEach((cat) => {
          cat.sections = cat.sections.filter((s) => s.id != section.id);
          const index = cat.sections.findIndex((c) => c.id == section.id);
          if (action.payload.categoryIds.includes(cat.id) && index < 0) {
            cat.sections = [...cat.sections, section];
          }
        });
      }
    },
    setSelectedSectionId: (state, action: PayloadAction<{ id: string }>) => {
      state.categories.forEach((cat) => (cat.expanded = false));
      state.selectedSectionId = action.payload.id;
    },
    setMenuCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addItemsToCategory,
  changeCategoryPositions,
  changeMenuItemPositions,
  setPlaces,
  setCategories,
  updateCategoryProperties,
  updateMenuItemProperties,
  searchItems,
  deleteMenuItem,
  deleteCategory,
  setOptionGroups,
  deleteOptionGroup,
  updateOptionGroupProperties,
  updateOptionGroupMenuItems,
  updateOptionGroupRule,
  deleteOption,
  addOption,
  updateOption,
  changePositionOption,
  changePositionOptionGroup,
  setSections,
  addSection,
  updateSection,
  removeSection,
  updateCategorySections,
  updateSectionCategories,
  setSelectedSectionId,
  setMenuCurrency,
} = PlaceSlice.actions;

export default PlaceSlice.reducer;
