import { AUTH, HOST_URL } from "../constant/api.constant";
import axiosInstance, { RestfulService } from "./restful.service";
import { PlacePayloadType } from "types/Place.type";

const createPlacesService = (userId: string, params: PlacePayloadType) => {
  return axiosInstance.post(HOST_URL + AUTH.PLACES(userId), params);
};

const createPlacesServiceFirst = (userId: string, params: PlacePayloadType) => {
  return axiosInstance.post(HOST_URL + AUTH.PLACES_FIRST(userId), params);
};

const getCategories = (userId: string, placeId?: string) => {
  return axiosInstance.get(
    HOST_URL + AUTH.CATEGORY(userId, placeId)
  );
};

const createCategory = (
  userId: string,
  placeId?: string,
  params?: { name?: string; status: number }
) => {
  return axiosInstance.post(HOST_URL + AUTH.CATEGORY(userId, placeId), params);
};

const createCategories = (
  userId: string,
  placeId: string,
  count: number,
  params: { name?: string; status: number, sectionId?: string },
  
) => {
  return axiosInstance.post(
    HOST_URL + AUTH.CATEGORIES(userId, placeId, count),
    params
  );
};

const createMenuItem = (userId: string, params?: any) => {
  return axiosInstance.post(HOST_URL + AUTH.MENU_ITEM(userId), params);
};

const createMenuItems = (userId: string, count: number, params?: any) => {
  return axiosInstance.post(HOST_URL + AUTH.MENU_ITEMS(userId, count), params);
};

const deletePlacesById = (userId: string, placeId?: string) => {
  return axiosInstance.delete(HOST_URL + AUTH.PLACES_BY_ID(userId, placeId));
};

const deleteMenuItem = (userId: string, params?: any) => {
  return axiosInstance.delete(
    HOST_URL + AUTH.MENU_ITEM(userId) + `/${params?.id}`
  );
};

const updateCategory = (
  userId: string,
  placeId?: string,
  categoryId?: string,
  params?: any
) => {
  return axiosInstance.put(
    HOST_URL + AUTH.CATEGORY(userId, placeId) + `/${categoryId}`,
    params
  );
};

const deleteCategory = (
  userId: string,
  placeId?: string,
  categoryId?: string,
  params?: any
) => {
  return axiosInstance.delete(
    HOST_URL + AUTH.CATEGORY(userId, placeId) + `/${categoryId}`
  );
};

const updatePlacesById = (userId: string, placeId?: string, params?: any) => {
  return axiosInstance.put(
    HOST_URL + AUTH.PLACES_BY_ID(userId, placeId),
    params
  );
};

const updateMenuItem = (userId: string, params?: any) => {
  return axiosInstance.put(
    HOST_URL + AUTH.MENU_ITEM(userId) + `/${params?.id}`,
    params
  );
};

const getPlacesService = (userId: string) => {
  return axiosInstance.get(HOST_URL + AUTH.PLACES(userId));
};

const getPlacesPublic = (name?: string) => {
  return axiosInstance.get(HOST_URL + AUTH.PLACES_PUBLIC(name));
};

const getPlacesById = (userId: string, placeId?: string) => {
  return axiosInstance.get(HOST_URL + AUTH.PLACES_BY_ID(userId, placeId));
};

const updateOrderModeById = (
  userId: string,
  placeId?: string,
  params?: any
) => {
  return axiosInstance.put(
    HOST_URL + AUTH.PLACES_BY_ID(userId, placeId),
    params
  );
};

const getMenuItemById = (userId: string, menuId?: string) => {
  return axiosInstance.get(HOST_URL + AUTH.MENU_ITEM_BY_ID(userId, menuId));
};

const addLanguage = (userId: string, placeId?: string, params?: any) => {
  return axiosInstance.post(HOST_URL + AUTH.LANGUAGE(userId, placeId), params);
};

const updatedLanguage = (userId: string, placeId?: string, params?: any) => {
  return axiosInstance.put(
    HOST_URL + AUTH.LANGUAGE(userId, placeId) + "/" + params.id,
    params
  );
};

const deleteLanguage = (userId: string, placeId?: string, id?: any) => {
  return axiosInstance.delete(
    HOST_URL + AUTH.LANGUAGE(userId, placeId) + "/" + id
  );
};

const searchOrder = (
  userId: string,
  placeId?: string,
  filter?: string,
  page?: number
) => {
  return axiosInstance.get(
    HOST_URL + AUTH.SEARCH_ORDER(userId, placeId, filter, page)
  );
};

const getOrderById = (userId: string, placeId?: string, orderId?: string) => {
  return axiosInstance.get(
    HOST_URL + AUTH.ORDER_BY_ID(userId, placeId, orderId)
  );
};

const updateOrderStatus = (
  userId: string,
  placeId?: string,
  orderId?: number,
  status?: number
) => {
  return axiosInstance.put(
    HOST_URL + AUTH.UPDATE_ORDER_STATUS(userId, placeId, orderId, status)
  );
};

const updatePaymentStatus = (
  userId: string,
  placeId?: string,
  orderId?: number,
  status?: number
) => {
  return axiosInstance.put(
    HOST_URL + AUTH.UPDATE_PAYMENT_STATUS(userId, placeId, orderId, status)
  );
};

const addPaymentMethod = (placeId?: string, params?: any) => {
  return axiosInstance.post(HOST_URL + AUTH.PAYMENT(placeId), params);
};

const updatePaymentMethod = (placeId?: string, paymentMethodId?: string, params?: any) => {
  return axiosInstance.put(HOST_URL + AUTH.UPDATE_PAYMENT(placeId, paymentMethodId), params);
};

const getPaymentMethod = (placeId?: string) => {
  return axiosInstance.get(HOST_URL + AUTH.GET_PAYMENT(placeId));
};

const delPaymentMethod = (placeId?: string, paymentMethodId?: string) => {
  return axiosInstance.delete(
    HOST_URL + AUTH.DEL_PAYMENT(placeId, paymentMethodId)
  );
};

const getInvoiceLink = (
  userId?: string,
  placeId?: string,
  orderId?: string
) => {
  return axiosInstance.get(
    HOST_URL + AUTH.GET_INVOICE(userId, placeId, orderId)
  );
};

const getOptionGroups = (userId: string, placeId?: string) => {
  return axiosInstance.get(
    HOST_URL + AUTH.OPTION_GROUP(userId, placeId)
  );
};

const createOptionGroup = (
  userId: string,
  placeId?: string,
  params?: { name?: string }
) => {
  return axiosInstance.post(
    HOST_URL + AUTH.OPTION_GROUP(userId, placeId),
    params
  );
};

const updateOptionGroup = (
  userId: string,
  placeId?: string,
  optionGroupId?: string,
  params?: any
) => {
  return axiosInstance.put(
    HOST_URL + AUTH.OPTION_GROUP(userId, placeId) + `/${optionGroupId}`,
    params
  );
};

const updateOptionGroupRule = (
  userId: string,
  placeId?: string,
  optionGroupId?: string,
  params?: any
) => {
  return axiosInstance.put(
    HOST_URL + AUTH.OPTION_GROUP(userId, placeId) + `/${optionGroupId}/rule`,
    params
  );
};

const deleteOptionGroup = (
  userId: string,
  placeId?: string,
  optionGroupId?: string,
  params?: any
) => {
  return axiosInstance.delete(
    HOST_URL + AUTH.OPTION_GROUP(userId, placeId) + `/${optionGroupId}`
  );
};

const applyOptionGroupToItems = (
  userId: string,
  placeId?: string,
  optionGroupId?: string,
  params?: { menuItemIds: string[] }
) => {
  return axiosInstance.put(
    HOST_URL + AUTH.OPTION_GROUP(userId, placeId) + `/${optionGroupId}/apply-to-items`,
    params
  );
};

const createOption = (
  userId: string,
  placeId?: string,
  optionGroupId?: string,
  params?: { name?: string; price?: number }
) => {
  return axiosInstance.post(
    HOST_URL + AUTH.OPTION(userId, placeId, optionGroupId),
    params
  );
};

const updateOption = (
  userId: string,
  placeId?: string,
  optionGroupId?: string,
  optionId?: string,
  params?: any
) => {
  return axiosInstance.put(
    HOST_URL + AUTH.OPTION(userId, placeId, optionGroupId) + `/${optionId}`,
    params
  );
};

const deleteOption = (
  userId: string,
  placeId?: string,
  optionGroupId?: string,
  optionId?: string,
  params?: any
) => {
  return axiosInstance.delete(
    HOST_URL + AUTH.OPTION(userId, placeId, optionGroupId) + `/${optionId}`
  );
};

const getBusinessInformation = (
  userId: string,
  placeId?: string,
) => {
  return axiosInstance.get(
    HOST_URL + AUTH.PLACES_BY_ID(userId, placeId) + `/business-information`
  );
};

const getBrandingInformation = (
  userId: string,
  placeId?: string,
) => {
  return axiosInstance.get(
    HOST_URL + AUTH.PLACES_BY_ID(userId, placeId) + `/branding-information`
  );
};

const getOrderSetting = (
  userId: string,
  placeId?: string,
) => {
  return axiosInstance.get(
    HOST_URL + AUTH.PLACES_BY_ID(userId, placeId) + `/order-setting`
  );
};

const updatePlaceFromTemplate = (userId: string, placeId?: string) => {
  return axiosInstance.put(HOST_URL + AUTH.PLACES(userId) + `/sync?placeId=${placeId}`, {});
};

const importItems = (userId: string, placeId: string, file: File, callback: any) => {
  let formData = new FormData();
  formData.append("file", file);
  
  return axiosInstance.post(HOST_URL + AUTH.PLACES_BY_ID(userId, placeId) + '/import', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }, onUploadProgress: (data: any) => {
      callback(Math.round((100 * data.loaded) / data.total))
    }}
  );
};


const getSections = (userId: string, placeId?: string) => {
  return axiosInstance.get(
    HOST_URL + AUTH.SECTION(userId, placeId)
  );
};

const createSection = (
  userId: string,
  placeId?: string,
  params?: { name?: string }
) => {
  return axiosInstance.post(
    HOST_URL + AUTH.SECTION(userId, placeId),
    params
  );
};

const updateSection = (
  userId: string,
  placeId?: string,
  sectionId?: string,
  params?: any
) => {
  return axiosInstance.put(
    HOST_URL + AUTH.SECTION(userId, placeId) + `/${sectionId}`,
    params
  );
};

const deleteSection = (
  userId: string,
  placeId?: string,
  sectionId?: string,
  params?: any
) => {
  return axiosInstance.delete(
    HOST_URL + AUTH.SECTION(userId, placeId) + `/${sectionId}`
  );
};

const updateCategorySections = (
  userId: string,
  placeId?: string,
  categoryId?: string,
  params?: { sectionIds: string[] }
) => {
  return axiosInstance.put(
    HOST_URL + AUTH.CATEGORY(userId, placeId) + `/${categoryId}/sections`,
    params
  );
};

const addCategoriesToSections = (
  userId: string,
  placeId?: string,
  sectionId?: string,
  params?: { categoryIds: string[] }
) => {
  return axiosInstance.put(
    HOST_URL + AUTH.SECTION(userId, placeId) + `/${sectionId}/categories`,
    params
  );
};

const PlaceService = {
  createPlacesService,
  createPlacesServiceFirst,
  getCategories,
  createCategory,
  createCategories,
  deleteCategory,
  createMenuItem,
  createMenuItems,
  deletePlacesById,
  deleteMenuItem,
  updatePlacesById,
  updateCategory,
  updateMenuItem,
  getPlacesService,
  getPlacesById,
  updateOrderModeById,
  getOrderById,
  getPlacesPublic,
  getMenuItemById,
  addLanguage,
  updatedLanguage,
  deleteLanguage,
  searchOrder,
  updateOrderStatus,
  updatePaymentStatus,
  addPaymentMethod,
  updatePaymentMethod,
  getPaymentMethod,
  delPaymentMethod,
  getInvoiceLink,
  getOptionGroups,
  createOptionGroup,
  updateOptionGroup,
  updateOptionGroupRule,
  deleteOptionGroup,
  applyOptionGroupToItems,
  createOption,
  updateOption,
  deleteOption,
  getBusinessInformation,
  getBrandingInformation,
  getOrderSetting,
  updatePlaceFromTemplate,
  importItems,
  getSections,
  createSection,
  updateSection,
  deleteSection,
  updateCategorySections,
  addCategoriesToSections
};

export default PlaceService;
